const config = {
	selectors: {
		xPos: 'xPos',
		yPos: 'yPos',
		random1: 'random1',
		random2: 'random2',
	},
	data: {
		random1to5: Math.floor(Math.random() * 5) + 1,
		textCharAppearance: 70, // milliseconds
	},
};

let setRandom = {
	setRandomNumber1(interval) {
		setInterval(() => {
			let number = Math.random();
			document.body.style.setProperty('--random1', number);
		}, interval);
	},

	setRandomNumber2(interval) {
		setInterval(() => {
			let number = Math.random();
			document.body.style.setProperty('--random2', number);
		}, interval);
	},

	init() {
		// Populate css custom properties with random numbers
		this.setRandomNumber1(200);
		this.setRandomNumber2(config.data.random1to5 * 1000);
	},
};

let mouseMove = {
	init() {
		document.addEventListener('mousemove', function (e) {
			let wh = window.innerHeight / 2,
				ww = window.innerWidth / 2,
				mouseXVal = (e.clientX - ww) / 100,
				mouseYVal = -((e.clientY - wh) / 100);

			document.body.style.setProperty('--mouseX', mouseXVal);
			document.body.style.setProperty('--mouseY', mouseYVal);
		});
	},
};

let animateTexts = {
	animate(text) {
		text.classList.remove('waiting');
		text.classList.add('animating');
		// Check for text presence
		if (text.innerText !== '') {
			const baseText = text.innerText;
			const charCount = baseText.length;
			let counter = 0;
			text.innerText = '';

			let interval = setInterval(function () {
				text.innerText = baseText.slice(0, charCount - (charCount - counter));
				counter++;

				if (counter > charCount) {
					clearInterval(interval);
					text.classList.remove('animating');
					text.classList.add('animated');
				}
			}, config.data.textCharAppearance);
		}
	},

	init() {
		let texts = document.querySelectorAll('.animatable');

		for (let i = 0; i < texts.length; i++) {
			let text = texts[i];

			window.addEventListener('scroll', function () {
				let textPosition = text.offsetTop;

				if (
					window.scrollY > textPosition &&
					text.classList.contains('waiting') &&
					!text.classList.contains('animating') &&
					!text.classList.contains('animated')
				) {
					animateTexts.animate(text);
				}
			});
		}
	},
};

let audioPlayer = {
	selectors: {
		player: '.audio-player audio',
		controls: '.controls',
		play: '.play',
		pause: '.pause',
	},

	init() {
		if (document.querySelector(this.selectors.player)) {
			this.construct();
		}
	},

	construct() {
		const player = document.querySelector(this.selectors.player);
		const play = document.querySelector(this.selectors.play);
		const pause = document.querySelector(this.selectors.pause);

		player.addEventListener('play', (event) => {
			play.classList.add('hidden');
			pause.classList.remove('hidden');
		});

		player.addEventListener('pause', (event) => {
			pause.classList.add('hidden');
			play.classList.remove('hidden');
		});

		play.addEventListener('click', (event) => {
			player.play();
			play.classList.add('hidden');
			pause.classList.remove('hidden');
		});

		pause.addEventListener('click', (event) => {
			player.pause();
			pause.classList.add('hidden');
			play.classList.remove('hidden');
		});
	},
};

let UATester = {
	UA: window.navigator.userAgent,

	init() {
		if (/firefox/gi.test(this.UA)) {
			document.querySelector('html').classList.add('firefox');
		}
	},
};

document.addEventListener('DOMContentLoaded', (event) => {
	UATester.init();
	setRandom.init();
	mouseMove.init();
	animateTexts.init();
	audioPlayer.init();
});
